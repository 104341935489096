import React, { ReactNode } from 'react';
import logo from './logo.svg';
import './App.scss';
import pdfFile from "./static/Jack Manley Resume.pdf";

function App(): JSX.Element {
    return (
        <div className="App">
            <div className="content">
                <AppSection classes="App-title" >Jack Manley</AppSection>
                <AppSection>
                    Full stack software developer with a passion for creating great
                    user experiences on desktop and mobile platforms.

                    Updating!
                </AppSection>
                <AppSection>
                    <a href={pdfFile} >Resume</a>
                </AppSection>
                <AppSection>
                    <a href="mailto:jackmanleydev@gmail.com">jackmanleydev@gmail.com</a>
                </AppSection>
            </div>
        </div>
    );
}

interface IAppSctionProps {
    children?: ReactNode
    classes?: string;
}

function AppSection(props: IAppSctionProps ): JSX.Element {
    return (
        <div className={"App-section " + props.classes}>
            { props.children }
        </div>
    );
}


export default App;
























// <img src={logo} className="App-logo" alt="logo" />
/*
  <header className="App-header">
                <div>Jack Manley</div>
                <div>About | Resume | Contact</div>
            </header>
            <section className="App-section">
                
                This is section 1
            </section>
            <section className="App-section">
                
                This is section 2
            </section>
            <section className="App-section">
               
                This is section 3
                <p>Open a PDF file <a href={pdfFile} >example</a>.</p>
            </section>
*/